import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoProvincia from "../../assets/old/img/logos-ase/ico-provincia.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="provincia">
        <Helmet>
            <title>Provincia Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Provincia Seguros: 0810-222-2444."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoProvincia}
                    alt="Provincia Seguros"
                />
                <h4 className="lead post-title text-muted">Provincia Seguros</h4>
                <p className="">Servicio de asistencia: 0810-222-2444.</p>
            </div>
        </div>

        <h6 className="pt-5 pb-3">¿Cómo denunciar un siniestro?</h6>
        <p>
            Provincia Seguros dispone para tu comodidad de distintas alternativas para gestionar la denuncia administrativa de un siniestro: canal telefónico. El personalizado, la app para smartphones.
        </p>
        <p>
            <a href="https://apps.provinciaseguros.com.ar/PSClientes/clienteHome" target="_blank" rel="noopener noreferrer">Ingresá al sitio de clientes e informá el siniestro on-line, los 7 días de la semana. </a><br/>
            <a href="https://landing.provinciaseguros.com.ar/psapp/?utm_source=webPS" target="_blank" rel="noopener noreferrer">Descargar App</a>
        </p>

        <p>
            Servicio de atención inmediata de siniestros con terceros lesionados y/o fallecidos (todos los días las 24hs): 0810-222-2444
        </p>


        <hr className="pb-4" />



    </SiniestrosLayout>
)
